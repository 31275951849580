$(function() {
    $(".confirm").submit(function(){
      var c = confirm("Estas seguro?");
      return c; //you can just return c because it will be true or false
    });
    $(".confirmLink").on("click",function(e){
      return confirm("Estas seguro?");
      // return c; //you can just return c because it will be true or false
    });
    $( ".datepicker" ).datetimepicker({
      format: 'Y-m-d H:i',
      lang:'es'
    });
    var config = {
                      toolbar:
                              [['Bold', 'Italic',  '-', 'Link', 'Unlink']],
                      height: '100px'
                  };
                  // $("textarea[name^='proyecto']").ckeditor(config);
    $('.textarea_ck').ckeditor(config);
    $(".accion").on("click", function(event){
      event.preventDefault();
      var link = $(this);
      $.ajax({
        url: link.data("accion"),
        success: function(data){
          console.log(data);
          link.attr("data-accion",data);
          link.data("accion",data);

          switch (link.data("tipo")) {
            case 'portada':
              link.toggleClass("btn-success").toggleClass("btn-warning");
              link.children().toggleClass("glyphicon-eye-open").toggleClass("glyphicon-eye-close");
              break;
            case 'privado':
              link.toggleClass("btn-success").toggleClass("btn-info");
              link.children().toggleClass("glyphicon-lock").toggleClass("glyphicon-globe");
              break;
            default:
            link.toggleClass("btn-success").toggleClass("btn-warning");
            link.children().toggleClass("glyphicon-eye-open").toggleClass("glyphicon-eye-close");
          }

        }
      });
      return false;
    });

    $(".infoPregunta").on("click",function(e){
      e.preventDefault();
      var id = $(this).data('preguntaid');
      var texto = $("#mensaje"+id).val();
      var asunto = $("#asunto"+id).val();
      $("#asunto").html(asunto);
      $("#mensaje").html(texto);
      $("#info").modal();
    });
    $('[name="online_presencial"]').on("change",function(){
      if(this.value=="online"){
        console.log("esto es online");
        $(".oculto").css('display','none');
        $(".visible").css('display','none');

      }else{
        $(".oculto").css('display','block');
        $(".visible").css('display','block');
      }
    });
    $('.dtables').dataTable();
    $('#carousel-video,#carousel-archivo,#carousel-enlace').carousel();
    


});
